








import Component from 'vue-class-component';
// import PatientMenu from '../../components/PatientMenu.vue';
import PractitionerMenu from '../../components/PractitionerMenu.vue';
import Vue from 'vue';
import { Practitioner } from '@/models';
import { SidebarLayout } from '../../../shared';
import { activeTab } from './activeTab';

@Component({
  name: 'PractitionersViewPage',
  components: {
    // PatientMenu,
    SidebarLayout,
    PractitionerMenu,
  },
})
export default class extends Vue {
  drawer = false;
  practitioner: Practitioner | null = null;
  activeTab = activeTab;

  async mounted(): Promise<void> {
    await this.getPatient();
  }

  get uuid(): string {
    return this.$route.params.uuid;
  }

  async getPatient(uuid: string = this.uuid): Promise<void> {
    const practitioner = await Practitioner.findOrFail(uuid);

    if (practitioner) {
      this.practitioner = practitioner;
    }
  }
}
