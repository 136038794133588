
































import AvatarInput from '@/components/inputs/AvatarInput.vue';
import Component from 'vue-class-component';
import Vue from 'vue';
import { Practitioner } from '@/models';
import { Prop, VModel } from 'vue-property-decorator';
import { confirmation } from '@/domains/shared/components/confirm-dialog/confirm';
import { routeNames } from '@/router/routeNames';

@Component({
  components: { AvatarInput },
  name: 'PractitionerMenu',
})
export default class extends Vue {
  @Prop() readonly user!: Practitioner;

  @VModel({ type: Number }) tab!: number;

  tabs = [
    { icon: 'mdi-account', text: 'Profile' },
    // { icon: 'mdi-paperclip', text: 'Attachments' },
    // { icon: 'mdi-message-processing', text: 'Messages' },
    // { icon: 'mdi-email-plus', text: 'Emails' },
    // { icon: 'mdi-chart-areaspline-variant', text: 'Assessments' },
  ];

  get canDelete(): boolean {
    return this.$storage.auth.state.user?.data.uuid != this.user.data.uuid;
  }

  async destroy(): Promise<void> {
    const confirm = await confirmation(`Are you sure you want to delete ${this.user.data.name}?`, {
      confirmText: 'Delete',
      cancelText: 'Cancel',
      confirmType: 'warn',
    });

    if (confirm) {
      this.user.delete();
      this.$router.push({ name: routeNames.practitioner.practitioners.all });
    }
  }
}
