






















import { HasAvatar } from '@/models';
import { PropType, defineComponent, ref } from '@/plugins/composition';

export default defineComponent({
  name: 'AvatarInput',
  props: {
    avatarable: {
      type: Object as PropType<HasAvatar>,
      required: true,
    },
  },
  setup(props) {
    const image = ref<Blob | null>(null);
    const loading = ref(false);

    const uploadAvatar = async () => {
      if (!image.value) {
        return;
      }

      loading.value = true;
      const formData = new FormData();
      formData.append('avatar', image.value);
      formData.append('avatarable_uuid', props.avatarable.uuid);

      await props.avatarable.uploadAvatar(formData);

      loading.value = false;
    };

    return {
      image,
      loading,
      uploadAvatar,
    };
  },
});
